import * as React from "react"

import Seo from "../components/seo"
import Homepageintroduction from "../components/homeintroduction"

import {Helmet} from "react-helmet";




const mainPage = ({}) => {








  return (


<div>
    <Seo
        title='Home | Eugen Ovidiu Chirovici'
        
        description="Eugen Ovidiu Chirovici's personal websie, where you may read more about his books, including the Book of Mirrors, or Eugen Ovidiu Chirovici himself.  "

        keywords='Eugen Ovidiu Chirovici, The Book of Mirrors, E.O. Chirovici, Eugen Chirovici, Bad Blood, Sleeping Dogs, Rumours, Gods Weapons and Money, Eugene Chirovici'

      />



<Homepageintroduction />






      </div>
  )

}


export default mainPage;
